import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { api } from '../utils';
import { colors } from '../theme';
import { translate } from '../utils/localization-helper';
import { toast } from 'react-toastify';
import Autosuggest from 'react-autosuggest';
import QRCode from "react-qr-code";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';

function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

const DynamicForm = ({ formConfig, objectId, loadMethod, updateMethod, saveMethod, removeMethod, listLocation, editPage, buttonTitle, saveCustomHandler, removeCustomHandler, navigationHandler, minimal, mapCoords, onChangeForm, mapZoom, initialData, removeTitle }) => {
  const [formData, setFormData] = useState({});
  const [fileData, setFileData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (!saveMethod) fetchData();
    }, 1)
    if (initialData) {
      setFormData(initialData);
      console.log('initialData', initialData)
    }
  }, [initialData]);

  const [suggestionsState, setSuggestions] = useState([])

  const onSuggestionSelected = (item, key) => {
    console.log('item', item);
    setFormData({ ...formData, [key]: item.id, [`${key}_value`]: item.name, ...item });
    if (onChangeForm) onChangeForm({ ...formData, [key]: item.id, [`${key}_value`]: item.name });
  };

  const isDisabledSubmitButton = (value, key) => {
    let isDisabled = false;
  
    formConfig.fields.map(field => {
      if (field.required && !formData[field.key]) {
        isDisabled = true;
      }
      
      // Проверяем поле phone
      if (field.required && !formData[field.key] && field.type === 'phone') {
        const phoneValue = formData[field.key];
        const phoneRegex = /^380\d{9}$/;
        if (!phoneRegex.test(phoneValue)) {
          console.log('isDisabled', isDisabled);
          isDisabled = true;
        }
      }
    });
  
    return isDisabled;
  };  

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.name}
    </div>
  );

  function replaceBracketsWithValue(url, object) {
    return url.replace(/\[(.*?)\]/g, (match, key) => {
      return object[key] || match;
    });
  }

  const fetchData = async () => {
    if (loadMethod) {
      const data = await api.jsonRpcRequest(loadMethod, { id: objectId });
      setFormData(data);
    }
  };

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    setFormData({ ...formData, [key]: value });
    if (onChangeForm) onChangeForm({ ...formData, [key]: value });
  };

  const handleCheckboxChange = (key) => {
    setFormData({ ...formData, [key]: !formData[key] });
    if (onChangeForm) onChangeForm({ ...formData, [key]: !formData[key] });
  };

  const handleDateChange = (value, key) => {
    const timezoneOffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    const timezoneAdjustedDate = new Date(value - timezoneOffset);

    setFormData({ ...formData, [key]: value });
    if (onChangeForm) onChangeForm({ ...formData, [key]: value });
  };

  const handleFileChange = async (e, key) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFileData({ ...fileData, [key]: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleView = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const renderFormFields = () => {
    return formConfig.fields.map(({ key, title, type, options, required, getSuggestionsFunction, renderSuggestionFunction }, index) => (
      <Form.Group key={key} className={!minimal && index > 0 ? 'mt-3' : null}>
        { type != 'checkbox' ? <Form.Label>{title} {required && <span className="text-danger">*</span>}</Form.Label> : null }
        {type === 'file' ? (
          <div>
            <Form.Control type="file" onChange={e => handleFileChange(e, key)} />
            {formData && formData.hasOwnProperty(key) && formData[key] ? (<Button variant="primary" style={{ marginRight: '10px', marginTop: '10px' }} onClick={() => handleView(formData[key])}>{translate('common.form.view')}</Button>) : null}
          </div>
        ) : type === 'select' ? (
          <Form.Control as="select" onChange={e => handleInputChange(e, key)}>
            <option value="">- - -</option>
            {options.map((option, index) => (
              <option key={index} value={option.value} selected={(formData && formData.hasOwnProperty(key) && formData[key] === option.value) ? true : false}>{option.label}</option>
            ))}
          </Form.Control>
        ) : type === 'qr' ? (
          <div>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "200px", width: "200px" }}
              value={formData && formData.hasOwnProperty(key) && formData[key] ? formData[key] : ''}
              viewBox={`0 0 256 256`}
            />
          </div>
        ) : type === 'checkbox' ? (
          <Form.Check
            type="checkbox"
            label={title}
            checked={formData && formData.hasOwnProperty(key) && formData[key] ? formData[key] : false}
            onChange={e => handleCheckboxChange(key)}
          />
        ) : type === 'autocomplete' ? (
          <Autosuggest
            suggestions={suggestionsState}
            onSuggestionsFetchRequested={async ({ value }) => { let data = await getSuggestionsFunction(value); console.log(data); setSuggestions(data ? data : []); }}
            onSuggestionsClearRequested={() => setSuggestions([])}
            getSuggestionValue={() => { return formData && formData.hasOwnProperty(key) && formData[key] ? formData[`${key}_value`] || formData[key] : '' }}
            renderSuggestion={renderSuggestionFunction ? renderSuggestionFunction : renderSuggestion}
            inputProps={{ className: 'form-control', value: formData && formData.hasOwnProperty(key) && formData[key] ? formData[`${key}_value`] || formData[key] : '', onChange: e => handleInputChange(e, key), placeholder: title }}
            onSuggestionSelected={(event, { suggestion }) => onSuggestionSelected(suggestion, key)}
          />
        ) : type === 'datetime' ? (
          <div>
            <DatePicker
              selected={formData && formData.hasOwnProperty(key) && formData[key] ? new Date(formData[key]) : ''}
              onChange={value => handleDateChange(value, key)}
              showTimeSelect
              dateFormat="dd.MM.yyyy HH:mm"
              timeFormat="HH:mm"
              className="form-control"
              wrapperClassName="col-12"
              timeIntervals={30}
              locale={undefined}
            />
          </div>
        ) : type === 'select_group' ? (
          <Form.Control as="select" onChange={e => handleInputChange(e, key)}>
            <option value="">- - -</option>
            {options.map((group, index) => (
              <optgroup key={index} label={group.label}>
                {group.options.map((option, idx) => (
                  <option key={idx} value={option.value} selected={formData && formData.hasOwnProperty(key) && formData[key] && formData[key] === option.value ? true : false}>{option.label}</option>
                ))}
              </optgroup>
            ))}
          </Form.Control>
        ) : type === 'phone' ? (
          <Form.Control
            type={'text'}
            placeholder={'380XXXXXXXXX'}
            value={formData && formData.hasOwnProperty(key) && formData[key] ? formData[key] : ''}
            onChange={e => handleInputChange(e, key)}
          />
        ) : (
          <Form.Control
            type={type === 'number' ? 'number' : 'text'}
            placeholder={title}
            value={formData && formData.hasOwnProperty(key) && formData[key] ? formData[key] : ''}
            onChange={e => handleInputChange(e, key)}
          />
        )}
        {errors[key] && <p className="text-danger text-small">{errors[key]}</p>}
      </Form.Group>
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = formConfig.fields.filter(field => field.required && !formData[field.key]);
    if (requiredFields.length > 0) {
      const newErrors = {};
      requiredFields.forEach(field => {
        newErrors[field.key] = translate('common.form.required');
      });
      setErrors(newErrors);
      return;
    }

    setErrors({});

    const formDataKeys = formConfig.fields.map(field => field.key);

    const filteredFormData = Object.keys(formData)
      .filter(key => formDataKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = formData[key];
        return obj;
      }, {});

    if (objectId) {
      filteredFormData.id = objectId;
    }

    Object.keys(fileData).forEach(key => {
      filteredFormData[key] = fileData[key];
    });

    if (saveCustomHandler) {
      const customHandlerData = Object.keys(formData)
        .filter(key => formDataKeys.includes(key) && formData[key])
        .reduce((obj, key) => {
          obj[key] = formData[key];
          return obj;
        }, {});
      saveCustomHandler(customHandlerData);
      //setFormData({});
      return;
    }

    let object = await api.jsonRpcRequest(saveMethod || updateMethod, filteredFormData);
    if (object && object.id) {
      toast.success(translate('common.toast.saved'))
      if (navigationHandler) {
        navigationHandler();
        return
      }
      if (editPage && saveMethod) navigate(replaceBracketsWithValue(editPage, object));
      if (!editPage && saveMethod) navigate(listLocation);
    }
  };

  const handleRemove = async (e) => {
    e.preventDefault();

    const confirmDelete = removeTitle ? true : window.confirm(translate('common.form.confirm_delete'));

    if (confirmDelete) {
      if (removeMethod) {
        await api.jsonRpcRequest(removeMethod, { id: objectId });
        toast.success(translate('common.toast.removed'))
        navigate(listLocation);
      }

      if (removeCustomHandler) {
        removeCustomHandler(objectId)
      }
    }
  };

  const needShowRemoveButton = () => {
    if (removeMethod) {
      return true;
    }
    if (removeCustomHandler) {
      return true;
    }
  }

  return (
    <div style={{ padding: 0 }}>
      {needShowRemoveButton() ? (<div className={minimal ? "d-flex justify-content-end mb-3" : "d-flex justify-content-end my-3"}>
        <button onClick={handleRemove} className="btn btn-danger">
          {removeTitle ? removeTitle : translate('common.form.remove')}
        </button>
      </div>
      ) : null}
      <Form onSubmit={handleSubmit} style={{ flex: 1, backgroundColor: minimal ? null : colors.backgroundSecondary, padding: minimal ? null : '10px', borderRadius: minimal ? null : '10px' }}>
        {renderFormFields()}
        {mapCoords && (
          <MapContainer className={'my-3'} center={mapCoords} zoom={mapZoom} style={{ height: '200px', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={mapCoords}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
            <ChangeView center={mapCoords} zoom={mapZoom} />
          </MapContainer>
        )}
        {isDisabledSubmitButton() ? <Button variant="primary" type="submit" className='mt-3' disabled>
          {buttonTitle || translate('common.form.save')}
        </Button> : <Button variant="primary" type="submit" className='mt-3'>
          {buttonTitle || translate('common.form.save')}
        </Button>}
      </Form>
    </div>
  );
};

export default DynamicForm;
