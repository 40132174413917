import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function OrderPromoCode({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        console.log(111, data);
        await api.jsonRpcRequest('orders.update.promo_code', { hash: orderInfo.hash, promo_code_id: data.promo_code_id });
        if(onCompleteFunction) onCompleteFunction()
    };

    const removeCustomHandler = async (objectId) => {
        await api.jsonRpcRequest('orders.update.promo_code', { hash: orderInfo.hash, promo_code_id: null });
        if(onCompleteFunction) onCompleteFunction()
    };

    const renderSuggestion = (suggestion) => (
        <div>
            { suggestion ? suggestion.code : '' }
        </div>
    );

    const getServiceSuggestions = async (value, key) => {
        let inputValue = value;
        try {
            inputValue = value;
        } catch {
            inputValue = value;
        }

        let data = await api.jsonRpcRequest('promo_code.list', { filters: { code__icontains: inputValue } });
        data = data.objects.map(item => {
            return { ...item, id: item.id, name: item.code };
        });
        return data;
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrdersPage.table.fields.promo_code'),
                'key': 'promo_code_id',
                'getSuggestionsFunction': getServiceSuggestions,
                'renderSuggestionFunction': renderSuggestion,
                'type': 'autocomplete',
                'required': true,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} removeCustomHandler={initialData && initialData.id ? removeCustomHandler : null} initialData={initialData} />
        </div>
    );
}

export default OrderPromoCode;
