import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { translate } from '../utils/localization-helper';
import { api } from '../utils';
import Cookies from 'js-cookie';

function LoginPage() {
    const navigate = useNavigate();

    const { isAuthenticated, setIsAuthenticated, setConfig } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        Cookies.remove('bearer_token');
        setIsAuthenticated(false);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(email && password){
            let data = await api.getBearerToken(email, password);
            console.log(data)
            if(data && data.user_app_session){
                let bearerToken = data.user_app_session.uuid;
                Cookies.set('bearer_token', bearerToken);
                setIsAuthenticated(true);
                
                let configData = await api.getUserConfig()
                if(configData.id){
                    setConfig(configData);
                }

                navigate('/');
            } else {
                Cookies.remove('bearer_token');
                setIsAuthenticated(false);
            }
        }
    };

    const handleLogin = () => {
        setIsAuthenticated(true);
        navigate('/home');
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">{ translate('LoginPage.emailLabel') }</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">{ translate('LoginPage.passwordLabel') }</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>

                                <button type="submit" className="btn btn-primary w-100">
                                    { translate('LoginPage.submitBtnLabel') }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
