import i18n from '../locale';
import { translate } from '../utils/localization-helper';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

class JsonRpcClient {
    constructor(baseURL = '/api/cms/rpc/') {
        this.baseURL = baseURL;
        this.apiKey = null;
    }

    async jsonRpcRequest(method, params = {}) {
        this.apiKey = Cookies.get('bearer_token');

        const url = `${this.baseURL}`;
        const requestData = {
            jsonrpc: '2.0',
            method: method,
            params: params
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ this.apiKey || '' }`,
                    'Cache-Control': 'no-cache',
                    'Accept-Language': i18n.locale,
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                return
            }

            const responseData = await response.json();
            console.log(responseData)
            if (responseData.result && responseData.result.error) {
                if(responseData.result.error.code == -1){
                    toast.error(translate('errors.promoCodeTotalActivationsExpired'))
                }
                if(responseData.result.error.code == -2){
                    toast.error(translate('errors.promoCodeClientActivationsExpired'))
                }
                if(responseData.result.error.code == -3){
                    toast.error(translate('errors.promoCodeNotFound'))
                }
                return responseData.result;
            }

            return responseData.result;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    async getBearerToken(email, password) {
        try {
            const result = await this.jsonRpcRequest('user.auth.get_bearer_token', { email, password });
            return result;
        } catch (error) {
            console.error('Error sending code:', error);
            throw error;
        }
    }

    async getUserConfig() {
        try {
            const result = await this.jsonRpcRequest('user.config', { });
            return result;
        } catch (error) {
            console.error('Error sending code:', error);
            throw error;
        }
    }

}

export const api = new JsonRpcClient();