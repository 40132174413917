import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { Spinner } from 'react-bootstrap';
import { translate } from '../utils/localization-helper';
import { colors } from '../theme';

function OrderEmployeeSearchItem({ orderInfo, onSelect, date, hour }) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();
    const [state, setState] = useState(0);
    const [dispatchInfo, setDispatchInfo] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    function adjustDate(dateString, hour) {
        var date = new Date(dateString);
        date.setHours(hour, 0, 0, 0);
        return date.toISOString();
    }

    const fetchData = async (data) => {
        let dataDispatch = await api.jsonRpcRequest('orders.dispatch.search', { id: orderInfo.id, date: adjustDate(date, hour) });
        setDispatchInfo(dataDispatch);
        setState(dataDispatch.state);
    };

    const openModal = async () => {
        onSelect(dispatchInfo, adjustDate(date, hour));
    };

    return (
        <div onClick={openModal}>
            { state == 0 ? (
                <Spinner animation="border" role="status" style={{ width: '1rem', height: '1rem', color: '#cbcbcb', fontSize: '11px' }}>
                </Spinner>
            ) : null }
            { state == 1 ? (
                <i class="bi bi-check-circle-fill" style={{ color: 'green' }}></i>
            ) : null }
            { state == 2 ? (
                <i class="bi bi-dash-circle-fill" style={{ color: '#bc000033' }}></i>
            ) : null }
            { state == 3 ? (
                <i class="bi bi-check-circle-fill" style={{ color: '#0083d7' }}></i>
            ) : null }
        </div>
    );
}

export default OrderEmployeeSearchItem;
