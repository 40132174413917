import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function OrderItem({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        console.log('data', data);
        if(initialData.id){
            await api.jsonRpcRequest('order_items.update', {id: initialData.id, order_id: orderInfo.id, ...data });
        } else {
            await api.jsonRpcRequest('order_items.add', {order_id: orderInfo.id, ...data });
        }
        if(onCompleteFunction) onCompleteFunction(initialData.id)
    };

    const removeCustomHandler = async (objectId) => {
        await api.jsonRpcRequest('order_items.remove', {id: initialData.id });
        if(onCompleteFunction) onCompleteFunction(initialData.id)
    };

    const renderSuggestion = (suggestion) => (
        <div>
            {suggestion.name}
            {suggestion.order_type && suggestion.order_type.address_locality ? <div>
                <span style={{ fontSize: '12px', color: '#333333' }}>{suggestion.order_type.name} - {suggestion.order_type.address_locality.name} - {suggestion.price}</span> 
            </div> : null}
        </div>
    );

    const getServiceSuggestions = async (value, key) => {
        const inputValue = value.trim().toLowerCase();

        let data = await api.jsonRpcRequest('order_type_items.list', { filters: { name__icontains: inputValue } });
        data = data.objects.map(item => {
            return { ...item, id: item.id };
        });
        return data;
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrderItem.form.service'),
                'key': 'order_type_item_id',
                'getSuggestionsFunction': getServiceSuggestions,
                'renderSuggestionFunction': renderSuggestion,
                'type': 'autocomplete',
                'required': true,
            },
            {
                'title': translate('OrderItem.form.price'),
                'key': 'price',
                'type': 'number',
                'required': true,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} removeCustomHandler={initialData.id ? removeCustomHandler : null} initialData={initialData} />
        </div>
    );
}

export default OrderItem;
