import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from '../components/DynamicForm';
import { colors } from '../theme';

function OrderEmployee({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        if(initialData.id){
            await api.jsonRpcRequest('orders.employees.update', {id: initialData.id, order_id: orderInfo.id, ...data });
        } else {
            await api.jsonRpcRequest('orders.employees.add', {order_id: orderInfo.id, ...data });
        }
        if(onCompleteFunction) onCompleteFunction(initialData.id)
    };

    const removeCustomHandler = async (objectId) => {
        await api.jsonRpcRequest('orders.employees.remove', {id: initialData.id });
        if(onCompleteFunction) onCompleteFunction(initialData.id)
    };

    const renderSuggestion = (suggestion) => (
        <div>
            {suggestion.full_name}
            <div>
                { suggestion.employee_position == 1 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.cleaner') }</span> : null }
                { suggestion.employee_position == 2 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.cleaner_manager') }</span> : null }
                { suggestion.employee_position == 3 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.sales_manager') }</span> : null }
                { suggestion.employee_position == 4 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.office_manager') }</span> : null }
                { suggestion.employee_position == 5 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.quality_assurance') }</span> : null }
            </div>
        </div>
    );

    const getServiceSuggestions = async (value, key) => {
        const inputValue = value.trim().toLowerCase();

        let data = await api.jsonRpcRequest('employees.list', { filters: { full_name__icontains: inputValue } });
        data = data.objects.map(item => {
            return { ...item, id: item.id, name: item.full_name };
        });
        return data;
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrderEmployee.form.employee'),
                'key': 'employee_id',
                'getSuggestionsFunction': getServiceSuggestions,
                'renderSuggestionFunction': renderSuggestion,
                'type': 'autocomplete',
                'required': true,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} removeCustomHandler={initialData.id ? removeCustomHandler : null} initialData={initialData} />
        </div>
    );
}

export default OrderEmployee;
