import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate } from '../utils/localization-helper';
import { useAuth } from '../AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MenuItem({ title, location, items }) {

    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    const isActive = () => {
        return window.location.pathname === location;
    };

    const setLocation = async (e, path) => {
        e.preventDefault();
        navigate(path);
    };
    if(items && items.length){
        return (
            <li className="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id={location} role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={setLocation} aria-current="page">{title}</a>
                <ul class="dropdown-menu" aria-labelledby={location}>
                    {items.map((item, index) => (
                        <li><button onClick={(e) => {setLocation(e, item.location)}} class="dropdown-item">{item.title}</button></li>
                    ))}
                </ul>
            </li>
        );
    }

    return (
        <li className="nav-item">
            <button class="nav-link" onClick={(e) => {setLocation(e, location)}} role="button">{title}</button>
        </li>
    );
}

function Menu({ children, customClassName }) {

    const navigate = useNavigate();
    const { config, isAuthenticated, setIsAuthenticated } = useAuth();

    const logout = () => {
        setIsAuthenticated(false);
        navigate('/login');
    };

    const accountingSubMenu = [];

    if(config && config.permissions && config.permissions.includes('accounting_client_debts')){
        accountingSubMenu.push(
            {
                'title': translate('Menu.client_debts'),
                'location': "/accounting/client/debts"
            }
        )
    }

    if(config && config.permissions && config.permissions.includes('accounting_accounting')){
        accountingSubMenu.push(
            {
                'title': translate('Menu.accounting'),
                'location': "/accounting"
            }
        )
    }

    if(config && config.permissions && config.permissions.includes('accounting_employee_debts')){
        accountingSubMenu.push(
            {
                'title': translate('Menu.employee_debts'),
                'location': "/accounting/employee/debts"
            }
        )
    }

    if(config && config.permissions && config.permissions.includes('accounting_calendar')){
        accountingSubMenu.push(
            {
                'title': translate('Menu.calendar'),
                'location': "/accounting/calendar"
            }
        )
    }

    if(config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings'))){
        accountingSubMenu.push(
            {
                'title': translate('Menu.analytics'),
                'location': "/analytics/common"
            }
        )
    }

    if(config && config.permissions && (config.permissions.includes('analytics_documents'))){
        accountingSubMenu.push(
            {
                'title': translate('Menu.analytics_documents'),
                'location': "/documents"
            }
        )
        accountingSubMenu.push(
            {
                'title': translate('Menu.analytics_documents_stock'),
                'location': "/documents/stock"
            }
        )
    }

    return (
        <div>
            <ToastContainer autoClose={1000} theme="colored" />
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-fixed-top">
                <div className="container">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            { config && config.permissions && config.permissions.includes('employees') ? <MenuItem title={translate('Menu.employees')} location={"/employees"} /> : null }
                            { config && config.permissions && config.permissions.includes('equipments') ? <MenuItem title={translate('Menu.equipment')} location={"/equipments"} /> : null }
                            { config && config.permissions && config.permissions.includes('orders') ? <MenuItem title={translate('Menu.orders')} location={"/orders"} /> : null }
                            { config && config.permissions && config.permissions.includes('orders') ? <MenuItem title={translate('Menu.tasks')} location={"/tasks"} /> : null }
                            { config && config.permissions && (config.permissions.includes('accounting_client_debts') || config.permissions.includes('accounting_accounting') || config.permissions.includes('accounting_employee_debts') || config.permissions.includes('accounting_calendar') || config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings')) ? <MenuItem title={translate('Menu.reports')} items={accountingSubMenu} /> : null }
                            { config && config.permissions && (config.permissions.includes('journal') || config.permissions.includes('journal_all')) ? <MenuItem title={translate('Menu.journal')} location={"/journal"} /> : null }
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container">
                <div className={customClassName ? customClassName : 'row'}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Menu;
