import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import { toast } from 'react-toastify';
import OrderAddress from '../components/OrderAddress';
import OrderItem from '../components/OrderItem';
import OrderTask from '../components/OrderTask';
import OrderEmployee from '../components/OrderEmployee';
import OrderEquipment from '../components/OrderEquipment';
import OrderEmployeeSearch from '../components/OrderEmployeeSearch';
import OrderScheduledDateTime from '../components/OrderScheduledDateTime';
import OrderPromoCode from '../components/OrderPromoCode';
import OrderSaleScript from '../components/OrderSaleScript';
import OrderComment from '../components/OrderComment';
import OrderAddPayment from '../components/OrderAddPayment';
import OrderEmployeeAddPayment from '../components/OrderEmployeeAddPayment';
import OrderPayments from '../components/OrderPayments';
import OrderEmployeePayments from '../components/OrderEmployeePayments';
import OrderState from '../components/OrderState';
import { colors } from '../theme';
import DynamicForm from '../components/DynamicForm';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import Modal from 'react-bootstrap/Modal';
import '../App.css';

import moment from 'moment';
import 'moment/locale/ru';

function convertMinutesToHumanReadable(minutes) {
  let duration = moment.duration(minutes, 'minutes');
  
  let hours = Math.floor(duration.asHours());
  let mins = duration.minutes();

  let formattedHours = String(hours).padStart(2, '0');
  let formattedMinutes = String(mins).padStart(2, '0');

  let humanReadable = `${formattedHours}:${formattedMinutes}`;

  return humanReadable;
}

function formatDateShort(isoDateString) {
  var date = new Date(isoDateString);
  var day = date.getDate().toString().padStart(2, '0');
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
  var year = date.getFullYear();
  var hours = date.getHours().toString().padStart(2, '0');
  var minutes = date.getMinutes().toString().padStart(2, '0');
  return day + "." + month + " " + hours + ":" + minutes;
}

function OrdersPage() {

  function formatDate(isoDateString) {
    var date = new Date(isoDateString);
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
    var year = date.getFullYear();
    var hours = date.getHours().toString().padStart(2, '0');
    var minutes = date.getMinutes().toString().padStart(2, '0');
    return day + "." + month + "." + year + " " + hours + ":" + minutes;
  }

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('orders')) navigate('/');
  }, [isAuthenticated, navigate]);

  const tableConfig = {
    'fields': [
      {
        'title': translate('OrdersPage.table.fields.address_name'),
        'key': 'address_name',
        'order_key': 'address_name',
      },
      {
        'title': translate('OrdersPage.table.fields.client_full_name'),
        'key': 'client_full_name',
        'order_key': 'client_full_name',
      },
      {
        'title': translate('OrdersPage.table.fields.client_phone'),
        'key': 'client_phone_numbers',
        'order_key': 'client_phone_numbers',
      },
      {
        'title': translate('OrdersPage.table.fields.scheduled_datetime'),
        'key': 'scheduled_datetime',
        'type': 'date',
        'order_key': 'scheduled_datetime',
      },
      {
        'title': translate('OrdersPage.table.fields.created_datetime'),
        'key': 'created_datetime',
        'type': 'date',
        'order_key': 'created_datetime',
      },
      {
        'title': translate('OrdersPage.table.fields.state'),
        'key': 'state_badge',
        'type': 'badge',
        'order_key': 'state__name',
      },
      {
        'title': translate('OrdersPage.table.fields.google_campaing'),
        'key': 'google_campaing',
        'order_key': 'google_campaing__name',
        'permission': 'analytics'
      },
      {
        'title': translate('OrdersPage.table.fields.price'),
        'key': 'price',
        'permission': 'analytics'
      },
      {
        'title': translate('OrdersPage.table.fields.order_employee_payments_sum'),
        'key': 'order_employee_payments_sum',
        'permission': 'analytics'
      },
      {
        'title': translate('OrdersPage.table.fields.user_fin_operations_sum'),
        'key': 'user_fin_operations_sum',
        'permission': 'analytics'
      },
      {
        'title': '',
        'key': 'tasks_dot',
        'type': 'dot',
      },
    ],
    'filters': [
      {
        'title': translate('AnalyticsCommonPage.form.from'),
        'key': 'created_datetime__gte',
        'type': 'datetime',
        'options': Array.from({ length: 12 }, (_, i) => ({ value: (i + 1).toString(), label: (i + 1).toString() }))
      },
      {
        'title': translate('AnalyticsCommonPage.form.to'),
        'key': 'created_datetime__lte',
        'type': 'datetime',
        'options': Array.from({ length: 6 }, (_, i) => ({ value: (2024 + i).toString(), label: (2024 + i).toString() }))
      },
      {
        'title': translate('OrdersPage.table.fields.group_state'),
        'key': 'state__group_name',
        'type': 'select',
        'options': config && config.order_states ? config.order_states.reduce((acc, item) => acc.some(group => group.value === item.group_name) ? acc : [...acc, { value: item.group_name, label: item.group_name }], []) : [],
      },
      {
        'title': translate('OrdersPage.table.fields.state'),
        'key': 'state_id',
        'type': 'select_group',
        'options': config && config.order_states ? config.order_states.reduce((acc, item) => { const group = acc.find(g => g.label === item.group_name); const option = { value: item.id, label: item.name }; group ? group.options.push(option) : acc.push({ label: item.group_name, options: [option] }); return acc; }, []) : [],
      },
      {
        'title': translate('OrdersPage.table.fields.address_name'),
        'key': 'address_name__icontains',
        'type': 'text',
      },
      {
        'title': translate('OrdersPage.table.fields.client_full_name'),
        'key': 'client__full_name__icontains',
        'type': 'text',
      },
      {
        'title': translate('OrdersPage.table.fields.client_phone'),
        'key': 'client__phone_numbers__phone__icontains',
        'type': 'phone',
      },
      {
        'title': translate('OrdersPage.table.fields.additional_filter'),
        'key': 'additional_filter',
        'type': 'select',
        'options': [
          { value: 'all', label: translate('OrdersPage.table.fields.additional_filter_all') },
          { value: 'active', label: translate('OrdersPage.table.fields.additional_filter_active') },
          { value: 'inactive', label: translate('OrdersPage.table.fields.additional_filter_inactive') },
          { value: 'success', label: translate('OrdersPage.table.fields.additional_filter_success') },
          { value: 'failed', label: translate('OrdersPage.table.fields.additional_filter_failed') },
        ],
      },
    ]
  };
  

  return (
    <Menu>
      <Table initialFilters={{ additional_filter: 'active' }} method={'orders.list'} updateMethod={'orders.update'} tableConfig={tableConfig} addPage={'/orders/add'} editPage={'/orders/[hash]'} editPageTab={true} />
    </Menu>
  );
}

function OrderAddPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('orders')) navigate('/');
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('OrdersPage.table.fields.client_phone'),
        'key': 'phone',
        'type': 'phone',
        'required': true,
      },
      {
        'title': translate('OrdersPage.table.fields.skip_if_exist'),
        'key': 'skip_if_exist',
        'type': 'checkbox',
        'required': false,
      }
    ]
  };

  return (
    <Menu>
      <DynamicForm saveMethod={'orders.add'} formConfig={formConfig} listLocation={'/orders'} editPage={'/orders/[hash]'} />
    </Menu>
  );
}

function OrderPage() {

  let { hash } = useParams();

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  const [orderInfo, setOrderInfo] = useState({});

  const [modalAddress, setModalAddress] = useState(false);
  const handleCloseModalAddress = () => setModalAddress(false);
  const handleShowModalAddress = () => setModalAddress(true);

  const [modalPromoCode, setModalPromoCode] = useState(false);
  const handleCloseModalPromoCode = () => setModalPromoCode(false);
  const handleShowModalPromoCode = (data) => {
    setModalPromoCode(true);
  };

  const [modalState, setModalState] = useState(false);
  const handleCloseModalState = () => setModalState(false);
  const handleShowModalState = (data) => {
    setModalState(true);
  };

  const [modalScheduledDateTime, setModalScheduledDateTime] = useState(false);
  const handleCloseModalScheduledDateTime = () => setModalScheduledDateTime(false);
  const handleShowModalScheduledDateTime = () => setModalScheduledDateTime(true);

  const [modalSaleScript, setModalSaleScript] = useState(false);
  const handleCloseModalSaleScript = () => setModalSaleScript(false);
  const handleShowModalSaleScript = () => setModalSaleScript(true);

  const [modalOrderPayments, setModalOrderPayments] = useState(false);
  const handleCloseModalOrderPayments = () => setModalOrderPayments(false);
  const handleShowModalOrderPayments = () => setModalOrderPayments(true);

  const [modalOrderEmployeeAddPayment, setModalOrderEmployeeAddPayment] = useState(false);
  const handleCloseModalOrderEmployeeAddPayment = () => setModalOrderEmployeeAddPayment(false);
  const handleShowModalOrderEmployeeAddPayment = () => setModalOrderEmployeeAddPayment(true);

  const [modalOrderEmployeePayments, setModalOrderEmployeePayments] = useState(false);
  const handleCloseModalOrderEmployeePayments = () => setModalOrderEmployeePayments(false);
  const handleShowModalOrderEmployeePayments = () => setModalOrderEmployeePayments(true);

  const addOrderEmployeePaymentHandler = async () => {
    setModalOrderEmployeePayments(false);
    setModalOrderEmployeeAddPayment(true);
  };

  const [modalOrderAddPayment, setModalOrderAddPayment] = useState(false);
  const handleCloseModalOrderAddPayment = () => setModalOrderAddPayment(false);
  const handleShowModalOrderAddPayment = () => setModalOrderAddPayment(true);

  const addOrderPaymentHandler = async () => {
    setModalOrderPayments(false);
    setModalOrderAddPayment(true);
  };

  const [modalOrderItem, setModalOrderItem] = useState(false);
  const [orderItemData, setOrderItemData] = useState({});
  const handleCloseModalOrderItem = () => { setModalOrderItem(false); setOrderItemData({}); };
  const handleShowModalOrderItem = (data) => {
    if (data.order_type_item) {
      data = { ...data, order_type_item_id_value: data.order_type_item.name, order_type_item_id: data.order_type_item.id }
    }
    setOrderItemData(data);
    setModalOrderItem(true);
  };

  const [modalOrderComment, setModalOrderComment] = useState(false);
  const [orderCommentData, setOrderCommentData] = useState({});
  const handleCloseModalOrderComment = () => { setModalOrderComment(false); setOrderCommentData({}); };
  const handleShowModalOrderComment = (data) => {
    if (data.order_type_item) {
      data = { ...data, order_type_item_id_value: data.order_type_item.name, order_type_item_id: data.order_type_item.id }
    }
    setOrderCommentData(data);
    setModalOrderComment(true);
  };

  const [modalOrderTaskAdd, setModalOrderTaskAdd] = useState(false);
  const [orderTaskAddData, setOrderTaskAddData] = useState({});
  const handleCloseModalOrderTaskAdd = () => { setModalOrderTaskAdd(false); setOrderTaskAddData({}); };
  const handleShowModalOrderTaskAdd = (data) => {
    if (data.order_type_item) {
      data = { ...data, order_type_item_id_value: data.order_type_item.name, order_type_item_id: data.order_type_item.id }
    }
    setOrderTaskAddData(data);
    setModalOrderTaskAdd(true);
  };

  const [modalOrderEmployee, setModalOrderEmployee] = useState(false);
  const [orderEmployeeData, setOrderEmployeeData] = useState({});
  const handleCloseModalOrderEmployee = () => { setModalOrderEmployee(false); setOrderEmployeeData({}); };
  const handleShowModalOrderEmployee = (data) => {
    if (data.employee) {
      data = { ...data, employee_id_value: data.employee.full_name, employee_id: data.employee.id }
    }
    setOrderEmployeeData(data);
    setModalOrderEmployee(true);
  };

  const [modalOrderEmployeeSearch, setModalOrderEmployeeSearch] = useState(false);
  const [orderEmployeeSearchData, setOrderEmployeeSearchData] = useState({});
  const handleCloseModalOrderEmployeeSearch = () => { setModalOrderEmployeeSearch(false); setOrderEmployeeSearchData({}); };
  const handleShowModalOrderEmployeeSearch = (data) => {
    setOrderEmployeeSearchData(orderInfo);
    setModalOrderEmployeeSearch(true);
  };

  const [modalOrderEquipment, setModalOrderEquipment] = useState(false);
  const [orderEquipmentData, setOrderEquipmentData] = useState({});
  const handleCloseModalOrderEquipment = () => { setModalOrderEquipment(false); setOrderEquipmentData({}); };
  const handleShowModalOrderEquipment = (data) => {
    if (data.employee) {
      data = { ...data, employee_id_value: data.employee.full_name, employee_id: data.employee.id }
    }
    setOrderEquipmentData(data);
    setModalOrderEquipment(true);
  };

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('orders')) navigate('/');
    setTimeout(() => {
      fetchData();
    }, 1)
  }, [isAuthenticated, navigate]);

  const fetchData = async () => {
    const data = await api.jsonRpcRequest('orders.info', { hash });
    if (data) {
      setOrderInfo(data);
    }
  };

  const onCompleteModal = () => {
    handleCloseModalOrderEmployeeAddPayment();
    handleCloseModalOrderTaskAdd();
    handleCloseModalOrderComment();
    handleCloseModalAddress();
    handleCloseModalPromoCode();
    handleCloseModalState();
    handleCloseModalOrderAddPayment();
    handleCloseModalOrderItem();
    handleCloseModalScheduledDateTime();
    handleCloseModalOrderEmployee();
    handleCloseModalOrderEmployeeSearch();
    fetchData();
  };

  const onCompleteOrderEmployeeSearchModal = () => {
    handleCloseModalOrderEmployeeSearch();
    fetchData();
  };

  function formatDate(isoDateString) {
    var date = new Date(isoDateString);
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
    var year = date.getFullYear();
    var hours = date.getHours().toString().padStart(2, '0');
    var minutes = date.getMinutes().toString().padStart(2, '0');
    return day + "." + month + "." + year + " " + hours + ":" + minutes;
  }

  function translateState(state){
    let states = {
      1: translate('common.order.states.new'),
      2: translate('common.order.states.wait_for_confirmation'),
      3: translate('common.order.states.confirmed'),
      4: translate('common.order.states.in_process'),
      5: translate('common.order.states.finished'),
      6: translate('common.order.states.completed'),
      7: translate('common.order.states.canceled'),
    }
    return states[state]
  }

  const openGoogleMaps = () => {
    if (orderInfo.address_latitude && orderInfo.address_longitude) {
      const label = encodeURIComponent(orderInfo.address_name || "Order Location");
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${orderInfo.address_latitude},${orderInfo.address_longitude}&query_place_id=${label}`;
      window.open(googleMapsUrl, '_blank');
    } else {
      alert('Coordinates are not available.');
    }
  };
  

  const openWaze = () => {
    if (orderInfo.address_latitude && orderInfo.address_longitude) {
      const label = encodeURIComponent(orderInfo.address_name || "Order Location");
      const wazeUrl = `https://waze.com/ul?ll=${orderInfo.address_latitude},${orderInfo.address_longitude}&navigate=yes&q=${label}`;
      window.open(wazeUrl, '_blank');
    } else {
      alert('Coordinates are not available.');
    }
  };
  

  if (!orderInfo) return

  return (
    <Menu customClassName={'e'}>

      <div className='row'>
        <div className='col-md-3 my-3'>
          <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div className=''>
              <ul className="list-group list-group-flush">
                <div className="orderInfoItem">
                  <div className="label">{translate('OrdersPage.table.fields.client_full_name')}</div>
                  {orderInfo.client && orderInfo.client.full_name ? <div className="value">{orderInfo.client.full_name}</div> : translate('common.form.empty')}
                  {orderInfo.client && orderInfo.client.phones.map((phone, index) => (
                    <div>{phone}</div>
                  ))}
                </div>
                <div className="orderInfoItem">
                  <div className="label">{translate('OrdersPage.table.fields.bonus_balance')}</div>
                  {orderInfo.client ? <div className="value">{orderInfo.client_bonus_balance || 0}</div> : translate('common.form.empty')}
                </div>
                <div className="orderInfoItem">
                  <div className="label">{translate('OrdersPage.table.fields.balance')}</div>
                  {orderInfo.client ? <div className="value">{orderInfo.client_balance}</div> : translate('common.form.empty')}
                </div>
                <div className="orderInfoItem">
                  <div className="label">
                    {translate('OrdersPage.table.fields.address_name')}
                    <i className="bi bi-three-dots-vertical" onClick={handleShowModalAddress}></i>
                    <i className="bi mx-1" onClick={openGoogleMaps}>google</i>
                    <i className="bi mx-1" onClick={openWaze}>waze</i>
                  </div>
                  {orderInfo.address_name ? <div className="value">{[orderInfo.address_name, orderInfo.address_apartment, orderInfo.address_comment].join(' ')}</div> : translate('common.form.empty')}
                </div>
                <div className="orderInfoItem">
                  <div onClick={handleShowModalScheduledDateTime} className="label">{translate('OrdersPage.table.fields.scheduled_datetime')} <i className="bi bi-three-dots-vertical"></i></div>
                  {orderInfo.scheduled_datetime ? <div className="value">{[formatDate(orderInfo.scheduled_datetime)].join(' ')}</div> : translate('common.form.empty')}
                </div>
                {orderInfo.client ? <div className="orderInfoItem">
                  <div onClick={handleShowModalState} className="label">{translate('OrdersPage.table.fields.state')} <i className="bi bi-three-dots-vertical"></i></div>
                  {orderInfo.state ? <div className="value">{orderInfo.state}</div> : translate('common.form.empty')}
                </div> : null}
                {orderInfo.address_name ? <div className="orderInfoItem">
                  <div className="label">{translate('OrdersPage.table.fields.price')}</div>
                  {orderInfo.price ? <div className="value">{orderInfo.price}</div> : translate('common.form.empty')}
                </div> : null}
                <div className="orderInfoItem">
                  <div onClick={handleShowModalPromoCode} className="label">{translate('OrdersPage.table.fields.promo_code')} <i className="bi bi-three-dots-vertical"></i></div>
                  {orderInfo.promo_code_name ? <div className="value">{orderInfo.promo_code_name}</div> : translate('common.form.empty')}
                </div>

                {config && config.permissions && config.permissions.includes('analytics') ? <div className="orderInfoItem">
                  <div className="label">{translate('OrdersPage.table.fields.google_campaing')}</div>
                  {orderInfo.google_campaing ? <div className="value">{orderInfo.google_campaing}</div> : translate('common.form.empty')}
                </div> : null}

              </ul>
            </div>
          </div>
          <div className='p-3 my-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div className='d-grid gap-3'>
              {orderInfo.key_crm_card_id ? <a target='_blank' href={`https://doglyanuto.keycrm.app/app/leads/${orderInfo.key_crm_card_id}`} className="btn btn-primary btn-block">
                KeyCRM
              </a> : null}
              <button target='_blank' className="btn btn-dark btn-block" onClick={handleShowModalSaleScript}>
                {translate('OrdersPage.form.sale_scripts')}
              </button>
              { config && config.permissions && config.permissions.includes('accounting_accounting') ? <button target='_blank' className="btn btn-secondary btn-block" onClick={handleShowModalOrderPayments}>
                {translate('OrdersPage.form.pay')}
              </button> : null }
              { config && config.permissions && config.permissions.includes('accounting_accounting') ? <button target='_blank' className="btn btn-secondary btn-block" onClick={handleShowModalOrderEmployeePayments}>
                {translate('OrdersPage.form.employee_payments')}
              </button> : null }
            </div>
          </div>

        </div>

        <div className='col-md-9 my-3'>
          <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div>
              <div className="d-flex justify-content-end">
                <button onClick={() => handleShowModalOrderItem({})} className="btn btn-success">
                  {translate('common.table.add_service')}
                </button>
              </div>
              {orderInfo.items && orderInfo.items.length ? <div className='mt-3'>
                {orderInfo.items && orderInfo.items.map((item, index) => (
                  <div className="orderItemContainer" style={{ marginTop: index ? '5px' : null }}>
                    <div className="orderItemColumn">
                      <div className="orderItemContent" style={{ justifyContent: 'flex-start' }}>
                        {item.order_type_item.name}
                      </div>
                    </div>
                    <div className="orderItemColumn" style={{ flex: '0.5' }}>
                      { item.order_type_item.work_time ? <div className="orderItemContent" style={{ justifyContent: 'flex-start' }}>
                        <i class="bi bi-clock mx-2"></i> {convertMinutesToHumanReadable(item.order_type_item.work_time)}
                      </div> : null }
                    </div>
                    <div className="orderItemColumn" style={{ flex: '0.5' }}>
                      <div className="orderItemContent" style={{ justifyContent: 'flex-end' }}>
                        <i class="bi bi-wallet2 mx-2"></i> {item.price}
                      </div>
                    </div>
                    <div className="orderItemColumn" style={{ flex: '0.5' }}>
                      <div className="orderItemContent" style={{ justifyContent: 'flex-end' }}>
                        <div className="d-flex">
                          <button className={"btn btn-primary btn-sm mx-1"} onClick={(e) => { e.preventDefault(); handleShowModalOrderItem(item); }} type="button"> <i class="bi bi-pencil"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> : null}
            </div>
          </div>

          <div className='p-3 mt-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div>
              <div className="d-flex justify-content-end">
                <button onClick={() => handleShowModalOrderEmployeeSearch({})} className="btn btn-warning mx-3">
                  {translate('common.table.search_staff')}
                </button>
                <button onClick={() => handleShowModalOrderEmployee({})} className="btn btn-success">
                  {translate('common.table.add_staff')}
                </button>
              </div>
              {orderInfo.employees && orderInfo.employees.length ? <div className='mt-3'>
                {orderInfo.employees && orderInfo.employees.map((employee, index) => (
                  <div className="orderItemContainer" style={{ marginTop: index ? '5px' : null }}>
                    <div className="orderItemColumn">
                      <div className="orderItemContent" style={{ justifyContent: 'flex-start' }}>
                        <div>
                          {employee.employee && employee.employee.full_name ? employee.employee.full_name : ''}
                          <div>
                            { employee.employee && employee.employee.employee_position && employee.employee.employee_position == 1 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.cleaner') }</span> : null }
                            { employee.employee && employee.employee.employee_position && employee.employee.employee_position == 2 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.cleaner_manager') }</span> : null }
                            { employee.employee && employee.employee.employee_position && employee.employee.employee_position == 3 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.sales_manager') }</span> : null }
                            { employee.employee && employee.employee.employee_position && employee.employee.employee_position == 4 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.office_manager') }</span> : null }
                            { employee.employee && employee.employee.employee_position && employee.employee.employee_position == 5 ? <span style={{ fontSize: '12px', color: '#333333' }}>{ translate('EmployeesPage.table.fields.quality_assurance') }</span> : null }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="orderItemColumn">
                      <div className="orderItemContent" style={{ justifyContent: 'flex-end' }}>
                        +{employee.employee && employee.employee.phone ? employee.employee.phone : null}
                      </div>
                    </div>
                    <div className="orderItemColumn">
                      <div className="orderItemContent" style={{ justifyContent: 'flex-end' }}>
                        <div className="d-flex">
                          <button className={"btn btn-primary btn-sm mx-1"} onClick={(e) => { e.preventDefault(); handleShowModalOrderEmployee(employee); }} type="button"> <i class="bi bi-pencil"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> : null}
            </div>
          </div>

          <div className='p-3 mt-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div>
              <div className="d-flex justify-content-end">
                <button onClick={() => handleShowModalOrderEquipment({})} className="btn btn-success">
                  {translate('common.table.add_equipment')}
                </button>
              </div>
              {orderInfo.equipments && orderInfo.equipments.length ? <div className='mt-3'>
                {orderInfo.equipments && orderInfo.equipments.map((equipment, index) => (
                  <div className="orderItemContainer" style={{ marginTop: index ? '5px' : null }}>
                    <div className="orderItemColumn">
                      <div className="orderItemContent" style={{ justifyContent: 'flex-start' }}>
                        {equipment.equipment.name}
                      </div>
                    </div>
                    <div className="orderItemColumn">
                      <div className="orderItemContent" style={{ justifyContent: 'flex-end' }}>

                      </div>
                    </div>
                    <div className="orderItemColumn">
                      <div className="orderItemContent" style={{ justifyContent: 'flex-end' }}>
                        <div className="d-flex">
                          <button className={"btn btn-primary btn-sm mx-1"} onClick={(e) => { e.preventDefault(); handleShowModalOrderEquipment(equipment); }} type="button"> <i class="bi bi-pencil"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> : null}
            </div>
          </div>

          <div className='p-3 mt-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div>
              <div className="d-flex justify-content-end">
                <button onClick={() => handleShowModalOrderComment({})} className="btn btn-success">
                  {translate('common.table.add_comment')}
                </button>
              </div>
              {orderInfo.comments && orderInfo.comments.length ? <div className='mt-3'>
                {orderInfo.comments && orderInfo.comments.map((comment, index) => (
                  <div className="orderItemContainer" style={{ marginTop: index ? '5px' : null }}>
                    <div className="orderItemColumn">
                      <div className="orderItemContent" style={{ justifyContent: 'flex-start' }}>
                        {comment.content}
                      </div>
                    </div>
                    <div className="orderItemColumn">
                      <div className="orderItemContent" style={{ justifyContent: 'flex-end', display: 'grid' }}>
                        <div>{comment.create_user}</div>
                        <div>{formatDate(comment.create_datetime)}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> : null}
            </div>
          </div>

          <div className='p-3 mt-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div>
              <div className="d-flex justify-content-end">
                <button onClick={() => handleShowModalOrderTaskAdd({})} className="btn btn-success">
                  {translate('common.table.add_task')}
                </button>
              </div>
              {orderInfo.tasks && orderInfo.tasks.length ? <div className='mt-3'>
                {orderInfo.tasks && orderInfo.tasks.map((task, index) => (
                  <div className="orderItemContainer" style={{ marginTop: index ? '5px' : null, backgroundColor: task.completed_datetime ? '#c5dec1': null }}>
                    <div className="orderItemColumn" style={{ }}>
                      <div className="orderItemContent" style={{ justifyContent: 'flex-start' }}>
                        {task.text}
                      </div>
                    </div>
                    <div className="orderItemColumn" style={{ flex: '0.5' }}>
                      { task.deadline_datetime ? <div className="orderItemContent" style={{ justifyContent: 'flex-start' }}>
                        <i class="bi bi-clock mx-2"></i> {formatDateShort(task.deadline_datetime)}
                      </div> : null }
                    </div>
                    <div className="orderItemColumn" style={{ flex: '0.5' }}>
                      <div className="orderItemContent" style={{ justifyContent: 'flex-end' }}>
                        <i class="bi bi-person mx-2"></i> {task.responsible_user}
                      </div>
                    </div>
                    <div className="orderItemColumn" style={{ flex: '0.5' }}>
                      <div className="orderItemContent" style={{ justifyContent: 'flex-end' }}>
                        <div className="d-flex">
                          {!task.completed_datetime ? <button className={"btn btn-primary btn-sm mx-1"} onClick={(e) => { e.preventDefault(); handleShowModalOrderTaskAdd(task); }} type="button"> <i class="bi bi-pencil"></i> </button> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> : null}
            </div>
          </div>
        </div>
            </div>

      <Modal size="lg" show={modalSaleScript} onHide={handleCloseModalSaleScript}>
        <Modal.Body>
          <OrderSaleScript orderInfo={orderInfo} onCompleteFunction={onCompleteModal} initialData={orderTaskAddData} />
        </Modal.Body>
      </Modal>

      <Modal show={modalOrderTaskAdd} onHide={handleCloseModalOrderTaskAdd}>
        <Modal.Body>
          <OrderTask orderInfo={orderInfo} onCompleteFunction={onCompleteModal} initialData={orderTaskAddData} />
        </Modal.Body>
      </Modal>

      <Modal show={modalOrderComment} onHide={handleCloseModalOrderComment}>
        <Modal.Body>
          <OrderComment orderInfo={orderInfo} onCompleteFunction={onCompleteModal} initialData={ orderInfo.state_id ? { state_id: orderInfo.state_id } : {}} />
        </Modal.Body>
      </Modal>

      <Modal show={modalState} onHide={handleCloseModalState}>
        <Modal.Body>
          <OrderState orderInfo={orderInfo} onCompleteFunction={onCompleteModal} initialData={ orderInfo.state_id ? { state_id: orderInfo.state_id } : {}} />
        </Modal.Body>
      </Modal>

      <Modal show={modalPromoCode} onHide={handleCloseModalPromoCode}>
        <Modal.Body>
          <OrderPromoCode orderInfo={orderInfo} onCompleteFunction={onCompleteModal} initialData={orderInfo.promo_code ? { ...orderInfo.promo_code, promo_code_id_value: orderInfo.promo_code.code, promo_code_id: orderInfo.promo_code.id } : {}} />
        </Modal.Body>
      </Modal>

      <Modal show={modalAddress} onHide={handleCloseModalAddress}>
        <Modal.Body>
          <OrderAddress orderInfo={orderInfo} onCompleteFunction={onCompleteModal} />
        </Modal.Body>
      </Modal>

      <Modal show={modalOrderItem} onHide={handleCloseModalOrderItem}>
        <Modal.Body>
          <OrderItem orderInfo={orderInfo} onCompleteFunction={onCompleteModal} initialData={orderItemData} />
        </Modal.Body>
      </Modal>

      <Modal show={modalOrderEmployee} onHide={handleCloseModalOrderEmployee}>
        <Modal.Body>
          <OrderEmployee orderInfo={orderInfo} onCompleteFunction={onCompleteModal} initialData={orderEmployeeData} />
        </Modal.Body>
      </Modal>

      <Modal show={modalOrderEquipment} onHide={handleCloseModalOrderEquipment}>
        <Modal.Body>
          <OrderEquipment orderInfo={orderInfo} onCompleteFunction={onCompleteModal} initialData={orderEquipmentData} />
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={modalOrderEmployeeSearch} onHide={handleCloseModalOrderEmployeeSearch}>
        <Modal.Body>
          <OrderEmployeeSearch orderInfo={orderInfo} onCompleteFunction={onCompleteOrderEmployeeSearchModal} initialData={orderEmployeeSearchData} />
        </Modal.Body>
      </Modal>

      <Modal show={modalScheduledDateTime} onHide={handleCloseModalScheduledDateTime}>
        <Modal.Body>
          <OrderScheduledDateTime orderInfo={orderInfo} onCompleteFunction={onCompleteModal} />
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={modalOrderEmployeePayments} onHide={handleCloseModalOrderEmployeePayments}>
        <Modal.Body>
          <OrderEmployeePayments orderInfo={orderInfo} onCompleteFunction={onCompleteModal} addOrderEmployeePaymentHandler={addOrderEmployeePaymentHandler} />
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={modalOrderPayments} onHide={handleCloseModalOrderPayments}>
        <Modal.Body>
          <OrderPayments orderInfo={orderInfo} onCompleteFunction={onCompleteModal} addOrderPaymentHandler={addOrderPaymentHandler} />
        </Modal.Body>
      </Modal>

      <Modal show={modalOrderAddPayment} onHide={handleCloseModalOrderAddPayment}>
        <Modal.Body>
          <OrderAddPayment orderInfo={orderInfo} onCompleteFunction={onCompleteModal} />
        </Modal.Body>
      </Modal>

      <Modal show={modalOrderEmployeeAddPayment} onHide={handleCloseModalOrderEmployeeAddPayment}>
        <Modal.Body>
          <OrderEmployeeAddPayment orderInfo={orderInfo} onCompleteFunction={onCompleteModal} />
        </Modal.Body>
      </Modal>

    </Menu>
  );
}

export { OrderPage };
export { OrderAddPage };
export { OrdersPage };